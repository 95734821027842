import React, { FC } from 'react';
import { questions } from "../../services/questionnaireFactory/questions";
import {Value} from "../../types/questions";
import { setDiastisisRecti } from '../../redux/slices/profileSlice';
import {useDispatch} from "react-redux";
import {RadioGroupQuestion} from "../primitives/RadioGroupQuestion";

export const DR: FC = (props) => {

  const dispatch = useDispatch();

  const handleChange = ( selected: Value|Value[]) => {
    dispatch(setDiastisisRecti(selected));
  }

  return (
    <RadioGroupQuestion  onChange={handleChange} query={questions.diastisis}/>
  );
};
