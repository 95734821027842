import React, {CSSProperties, FC} from 'react';
import {CheckBox, CheckBoxProps} from "./CheckBox";

export interface CheckBoxWithLabelProps extends CheckBoxProps {
  label: string;
  containerStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  dimWhenNotSelected?: boolean;
}


export const CheckBoxWithLabel: FC<CheckBoxWithLabelProps> = ({
                                               label,
                                               containerStyle,
                                               labelStyle,
                                               dimWhenNotSelected,
                                               children,
                                               ...rest
                                             }) => {

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'flex-start'
    } as CSSProperties,
    label: {
      marginLeft: 10,
      opacity: dimWhenNotSelected ?
        (rest.checked ? 1.0 : 0.7) : 1
    }
  }

  return (
    <div style={{...styles.container, ...containerStyle}}>
      <CheckBox {...rest}/>
      <span style={{...styles.label, ...labelStyle}}>{label}</span>
    </div>
  );
};




