import React, { FC } from 'react';
import { Form, Button } from "react-bootstrap";

const CTA = 'Please let us know how to reach you. Our trainers will look at your responses and send you your custom workout as soon as possible!'

export const ContactInfo: FC = () => {

  return (
    <div>
      <h4>Name and Email</h4>
      <p>{CTA}</p>
      <Form>
        <Form.Group>
          <Form.Label>First Name</Form.Label>
          <Form.Control type="text" placeholder="First name" size="lg"/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Last Name</Form.Label>
          <Form.Control type="text" placeholder="Last name" size="lg"/>
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Email" size="lg"/>
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
      </Form>
    </div>
  );
};
