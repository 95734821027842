import { configureStore } from '@reduxjs/toolkit';
import profileReducer, {ProfileState} from './slices/profileSlice';
import contactReducer, {ContactInfoState} from './slices/contactInfoSlice';

export interface RootState {
  profile: ProfileState;
  contactInfo: ContactInfoState;
}

export default configureStore({
  reducer: {
    profile: profileReducer,
    contactInfo:contactReducer
  }
});
