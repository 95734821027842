import React, {FC} from 'react';
import {Route, Switch} from "react-router-dom";
import {PrenatalOnboardingQ} from "../pages/questionnaires/PrenatalOnboardingQ";
import {PostnatalOnboardingQ} from "../pages/questionnaires/PostnatalOnboardingQ";
import {LandingPage} from "../pages/landing/LandingPage";
import {TestPage} from "../pages/test/TestPage";

export const RootRoute: FC = () => {
  return (
    <Switch>
      <Route path="/questionnaire/prenatal">
        <PrenatalOnboardingQ />
      </Route>
      <Route path="/questionnaire/postnatal">
        <PostnatalOnboardingQ/>
      </Route>
      <Route path="/t">
        <TestPage/>
      </Route>
      <Route path="/">
        <LandingPage/>
      </Route>
    </Switch>
  );
};
