/*********************************

 File:       themecolors.js
 Function:   JS Access to the Theme Colors
 Copyright:  AppDelegates LLC
 Date:       2/26/2019
 Author:     mkahn

 Unfortunately there is no easy linkage from SCSS to JS.

 **********************************/

export const THEME_COLORS = {
    darkPurple: '#4a276a', // New Dark purple #684482
    lightPurple: '#684680', // New Light purple #c8bcd2
    ultralightPurple: '#d4c1e1',
    darkPurple1: '#684482',
    darkPurple2: '#8f6ca8',
    lightPurple1: '#c8bcd2',
    orange1: '#ff7f5b',
    pink: '#c974b1',
    mediumPurple: '#8f70a4',
    ultralightGray: '#dddddd',
    lightGray: '#999999',
    faintGray: '#eeeeee',
    gray: '#666666',
    imageOverlay: 'rgba(0, 0, 0, 0.4)',
    red: '#ff0000',
    danger: '#ff7d90',
    // From coolors.co
    greyBrown: '#54494b',
    lightGreyBrown: '#e0e2db',
    mediumGreyBrown: '#a6a8a1',
    beige: '#f2f7de',
    darkGray: '#464646',
    greenVine: '#295e29',
    white: '#ffffff',
    black: '#000000',
    nearBlack: '#393939',
    darkNavActive: '#ffd500',
    darkNavInactive: '#b08eb0',
    // greyscales
    grey0: '#393e42',
    grey1: '#43484d',
    grey2: '#5e6977',
    grey3: '#86939e',
    grey4: '#bdc6cf',
    grey5: '#e1e8ee',
    greyOutline: '#bbb',
    lightPink: '#fdb8e5',
};
