import React, { FC } from 'react';
import { questions } from "../../services/questionnaireFactory/questions";
import {RadioQuestionBox} from "../questionBox/RadioQuestionBox";
import {Value, ValueOrArray} from "../../types/questions";
import { setPelvicPain } from '../../redux/slices/profileSlice';
import {useDispatch} from "react-redux";
import {CheckboxQuestionBox} from "../questionBox/CheckboxQuestionBox";
import {CheckBoxGroupQuestion} from "../primitives/CheckBoxGroupQuestion";

interface Props {}



export const PelvicPain: FC<Props> = (props) => {

  const dispatch = useDispatch();

  const handleChange = ( selected: ValueOrArray) => {
    dispatch(setPelvicPain(selected));
  }

  return (
    <CheckBoxGroupQuestion query={questions.pelvicPain} onChange={handleChange}/>
  );
};
