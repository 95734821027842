import React, { FC } from 'react';
import {BackPain} from "../../components/questions/BackPain";
import {PelvicPain} from "../../components/questions/PelvicPain";
import {PelvicFloorConcerns} from "../../components/questions/PelvicFloorConcerns";
import {Sequencer} from "../../components/sequencer/Sequencer";
import {Container, Row, Col} from "react-bootstrap";
import {QuestionHolder} from "../../components/Containers/QuestionHolder";
import {PickBirthDate} from "../../components/questions/DatePicker";
import {BirthType} from "../../components/questions/BirthType";
import {SciaticaPain} from "../../components/questions/SciaticaPain";
import {DR} from "../../components/questions/DR";
import {PostMostInterested} from "../../components/questions/PostMostInterested";
import {ContactInfo} from "../../components/questions/ContactInfo";


const screens = [
  ContactInfo,
  PickBirthDate,
  BirthType,
  PelvicFloorConcerns,
  BackPain,
  PelvicPain,
  SciaticaPain,
  DR,
  PostMostInterested
]

interface Props {}

export const PrenatalOnboardingQ: FC<Props> = (props) => {

  return (
    <QuestionHolder>
      <Container>
        <Row>
          <Col>
            {/*<h1>Postnatal Questionnaire</h1>*/}
            <Sequencer screens={screens}/>
          </Col>
        </Row>
      </Container>
    </QuestionHolder>

  );
};
