import React, {CSSProperties, FC} from 'react';
import {
  IoMdCheckbox,
  IoMdCheckboxOutline,
  IoMdRadioButtonOff,
  IoMdRadioButtonOn,
  IoMdSquare,
  IoMdSquareOutline
} from 'react-icons/io';
import Color from 'color';

export interface CheckBoxProps {
  checked: boolean;
  onPress: () => void;
  size?: 'sm' | 'md' | 'lg';
  borderWidth?: number;
  color?: string;
  radio?: boolean;
}



export const CheckBox: FC<CheckBoxProps> = ({checked, onPress, size = 'lg', color = '#000', radio, borderWidth = 8}) => {

  const styles = {
    dot: {
      width: 40,
      height: 40,
      borderRadius: 40,
      border: `${borderWidth}px solid ${color}`,
      transition: 'background-color 0.2s'
    } as CSSProperties
  }

  const CheckSquare = ( checked: boolean) => {
    return checked ? <IoMdCheckbox size={40} color={color}/> : <IoMdSquareOutline size={40} color={color}/>
  }

  const CheckCircle = ( checked: boolean) => {
    return checked ? <IoMdRadioButtonOn size={40} color={color}/> : <IoMdRadioButtonOff size={40} color={color}/>
  }

  return (
    <div onClick={onPress}>
      {radio ? CheckCircle(checked) :
        CheckSquare(checked) }
    </div>
  );
};


