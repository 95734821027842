import React, {FC, useState} from 'react';
import {LabelValue} from "../../types/questions";
import {CheckBoxWithChildren} from "./CheckBoxWithChildren";
import {without} from 'lodash';
import {THEME_COLORS} from "../../assets/styles/themecolors";

export type Value = string | number;

interface CheckBoxGroupProps {
  entries: LabelValue<Value>[];
  onChange: (state: Value | Value[]) => void;
  clearOthersOnNone?: boolean;
  radio?: boolean;
}

export const CheckBoxGroup: FC<CheckBoxGroupProps> = ({entries, onChange, clearOthersOnNone = 'false', radio = false}) => {

  const [selected, setSelected] = useState<Value[]>([]);

  const toggleValue = (value: Value) => {

    if (radio){
      setSelected([value]);
      onChange(value);
      return;
    }

    if (value==='none' && clearOthersOnNone) {
      setSelected(['none']);
      onChange(['none']);
      return;
    }
    const newSelectedWithNone = selected.indexOf(value) > -1 ? without(selected, value) : [...selected, value];
    const newSelected = without(newSelectedWithNone, 'none');
    onChange(newSelected);
    setSelected(newSelected);
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'flex-start'}}>
      {entries.map((e) => <CheckBoxWithChildren
          onPress={() => toggleValue(e.value)}
          checked={selected.indexOf(e.value) > -1}
          label={e.label}
          color={THEME_COLORS.darkPurple}
          radio={radio}
          labelStyle={{ fontSize: 22}}
          outerContainerStyle={{ width: 300, marginBottom: 30}}
        >
          {e.image ? <img src={e.image} style={{ width: 150, alignSelf: 'center'}}/> : null}
        </CheckBoxWithChildren>
      )}
    </div>
  );
};
