import React, { FC, useState } from 'react';
import {Button} from "react-bootstrap";
import { motion } from "framer-motion";

interface Props {
  screens: FC[]
}

export const Sequencer: FC<Props> = ({ screens }) => {

  const [ index, setIndex ] = useState(0);

  const handleNext = () => {
    setIndex((index+1) % screens.length)
  }

  const handleBack = () => {
    setIndex((index-1) % screens.length)
  }

  const Screen = screens[index];

  return (
    <div>
      <motion.div animate={{ opacity: 1}}>
        <Screen/>
      </motion.div>
      { index ? <Button onClick={handleBack} className="float-left">Back</Button> : null }
      <Button onClick={handleNext} className="float-right">Next</Button>
      <div style={{ border: '1px solid red', padding: 10}} className="mt-5">
        <p> {index + 1}  of {screens.length} screens.</p>
      </div>
    </div>
  );
};
