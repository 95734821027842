import React, {CSSProperties} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {UIProvider} from "./services/ui/UIProvider";
import {RootRoute} from "./routes/RootRoute";
import {Provider} from "react-redux";
import store from './redux/store';
import useWindowDimensions from "./hooks/useWindowDimensions";
import mama from "./assets/images/landingPrenatal.jpg";
import narrowMama from "./assets/images/landingPrenatalNarrow.jpg";
import logo from "./assets/images/tlm.png";
import {Col, Container, Row} from "react-bootstrap";

type Value = string | number;

function App() {

  const dims = useWindowDimensions();

  const squat = dims.width / dims.height > 2;

  const styles = {
    container: {
      // backgroundImage: `url(${dims.width > 1000 ? mama : narrowMama})`,
      // backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: '100%',
      //padding: '22vh 7vw 10vh 7vw',
    } as CSSProperties,
    logo: {
      // zIndex: 2,
      // position: 'fixed',
      // top: 0,
      // left: 0,
      //width: squat ? 150 : 200,
      // border: '1px solid red',
      padding: 40,
      maxWidth: 250,
      //width: '20vw'
    } as CSSProperties
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <UIProvider>
          <div style={styles.container}>
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <img src={logo} style={styles.logo} className="img-fluid"/>
                </Col>
              </Row>
              <Row>
                <Col/>
                <Col xs={10} md={8} lg={6}>
                  <RootRoute/>
                </Col>
                <Col/>
              </Row>
            </Container>
          </div>

        </UIProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
