import React, { FC } from 'react';
import { questions } from "../../services/questionnaireFactory/questions";
import {Value, ValueOrArray} from "../../types/questions";
import { setPelvicFloorConcerns } from '../../redux/slices/profileSlice';
import {useDispatch} from "react-redux";
import {CheckBoxGroupQuestion} from "../primitives/CheckBoxGroupQuestion";

interface Props {}



export const PelvicFloorConcerns: FC<Props> = (props) => {

  const dispatch = useDispatch();

  const handleChange = ( values: ValueOrArray ) => {
    dispatch(setPelvicFloorConcerns(values));
  }

  return (
    <CheckBoxGroupQuestion query={questions.pelvicFloorConcerns} onChange={handleChange}/>
  );
};
