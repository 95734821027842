import React, {FC} from 'react';
import {MultipleChoiceQuestion, ValueOrArray} from "../../types/questions";
import {RadioGroup} from "../primitives/RadioGroup";

interface RadioGroupQuestionProps {
  query: MultipleChoiceQuestion<any>
  onChange: (state: ValueOrArray) => void;
}

export const RadioGroupQuestion: FC<RadioGroupQuestionProps> = ({
                                                                  query,
                                                                  onChange
                                                                }) => {

  const {choices, question, required, image} = query;
  return (
    <div>
      <h2 className="mb-4">{question}</h2>
      <RadioGroup
        entries={choices}
        onChange={onChange}>
        {image ? <img src={image} style={{width: 300, margin: 30}}/> : null}
      </RadioGroup>
    </div>
  );
};
