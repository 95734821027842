import React, {CSSProperties, FC} from 'react';
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import mama from '../../assets/images/landingPrenatal.jpg';
import narrowMama from '../../assets/images/landingPrenatalNarrow.jpg';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {CheckBox} from "../../components/primitives/CheckBox";


interface Props {}

export const LandingPage: FC<Props> = (props) => {

  const { isPhone } = useWindowDimensions()

  const styles = {
    container: {
      backgroundColor: 'rgba(255,255,255,0.8)',
      minHeight: '70vh',
      borderRadius: 10,
      padding: isPhone ? 10 : 20
    } as CSSProperties
  }

  return (
    <div style={styles.container}>
      <h1>Free customized Lotus Method pre/postnatal workout</h1>
      <p>Answer these few questions and we will send you a customized workout within 24 hours designed by one of our Pre/Postnatal Exercise Specialists specifically for you based on your answers.  Because every pregnancy and postpartum experience is unique!</p>
      <p>Are you...?</p>
      <Button as={Link} to="/questionnaire/prenatal" className="w-100 mb-2">Prenatal</Button>
      <Button as={Link} to="/questionnaire/postnatal" className="w-100 mb-2">Postnatal</Button>
      <Button as={Link} to="/questionnaire/trying" className="w-100 mb-2">Trying to conceive</Button>
    </div>
  );
};



