import { createSlice } from '@reduxjs/toolkit'

export interface ContactInfoState {
  firstName: string;
  lastName: string;
  email: string;
  pregState?: string;
}

export const counterSlice = createSlice({
  name: 'contactInfo',
  initialState: {
    firstName: '',
    lastName: '',
    email: '',
    pregState: undefined
  },
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPregState: (state, action) => {
      state.pregState = action.payload;
    }
  }
})

export const { setFirstName, setLastName, setEmail, setPregState } = counterSlice.actions

export default counterSlice.reducer
