import pelvicLeak from '../../assets/images/questions/pelvic-leak.png';
import pelvicProlapse from '../../assets/images/questions/pelvic-prolapse.png';
import pelvicHypertonic from '../../assets/images/questions/pelvic-hypertonic.png';
import none from '../../assets/images/questions/none.png';
import noneTall from '../../assets/images/questions/nonetall.png';
import backPain from '../../assets/images/questions/back-pain.jpg';
import roundLigamentPain from '../../assets/images/questions/round-ligament.png';
import sharpPelvic from '../../assets/images/questions/sharpPelvicPain.png';
import groinPelvic from '../../assets/images/questions/groinPelvicPain.png';
import spinePelvic from '../../assets/images/questions/spinePelvicPain.png';
import sciaticaOne from '../../assets/images/questions/sciaticaOne.png';
import sciaticaTwo from '../../assets/images/questions/sciaticaTwo.png';
import dr from '../../assets/images/questions/drecti.png';

export const questions = {
  pregnancyStage: {
    type: 'radio',
    required: true,
    question: 'Are you pregnant, postpartum or trying to conceive?',
    choices: [
      {label: 'Pregnant', value: 'pregnant'},
      {label: 'Postpartum', value: 'postpartum'},
      {label: 'Trying to conceive', value: 'trying'}
    ]
  },
  dueDate: {
    type: 'date',
    question: 'When is your due date?'
  },
  pelvicFloorConcerns: {
    type: 'checkbox',
    fieldName: 'pelvicFloorConcerns',
    required: true,
    question: 'Are you experiencing any pelvic floor concerns?',
    choices: [
      {
        label: 'Experiencing leakage during coughing, sneezing, running, jumping, etc,',
        image: pelvicLeak,
        value: 'leakage'
      },
      {
        label: 'Unable to relax pelvic floor, tailbone pain, painful sex, diagnosed hypertonic.',
        image: pelvicHypertonic,
        value: 'hypertonic'
      },
      {
        label: 'Heaviness/pressure in vagina & pelvic floor, diagnosed prolapse.',
        image: pelvicProlapse,
        value: 'prolapse'
      },
      {
        label: 'None of the above.',
        image: none,
        value: 'none'
      }
    ]
  },
  backPain: {
    type: 'radio',
    fieldName: 'backPain',
    required: true,
    question: 'Are you experiencing any back pain?',
    image: backPain,
    choices: [
      {
        label: 'Upper back',
        value: 'upper'
      },
      {
        label: 'Lower back',
        value: 'lower'
      },
      {
        label: 'Both',
        value: 'both'
      },
      {
        label: 'No back pain',
        value: 'none'
      }
    ]
  },
  roundLigamentPain: {
    type: 'radio',
    image: roundLigamentPain,
    fieldName: 'roundLigamentPain',
    required: true,
    question: 'Are you currently experiencing round ligament pain? Typically described as a sharp shooting pain or dull ache on the side of the belly.',
    choices: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      }
    ]
  },
  diastisis: {
    type: 'radio',
    image: dr,
    fieldName: 'disatisisRecti',
    required: true,
    question: 'Have you been diagnosed with Diastasis Recti (abdominal separation)?',
    choices: [
      {
        label: 'Yes, I have Diastasis Recti',
        value: 'yes'
      },
      {
        label: `I'm not sure, but  I think I might`,
        value: 'maybe'
      },
      {
        label: `No, I do not have diastisis recti`,
        value: 'no'
      }
    ]
  },
  pelvicPain: {
    type: 'radio',
    columns: true,
    fieldName: 'pelvicPain',
    required: true,
    question: 'Are you currently experiencing any pelvic pain? Often experienced while walking, turning in bed, putting on pants etc.',
    choices: [
      {
        label: 'Sharp shooting pain straight up the center of the pelvis',
        value: 'sharp-shooting-center',
        image: sharpPelvic
      },
      {
        label: 'Feels like a groin pull or strain in the inner thigh- front of pelvis',
        value: 'groin-pull',
        image: groinPelvic
      },
      {
        label: 'Ache or sharp pain at the base of the spine and or radiating in the glute',
        value: 'spine-glute',
        image: spinePelvic
      },
      {
        label: 'None of the above',
        value: 'none',
        image: none
      }
    ]
  },
  vaginalOrCesareanBirth: {
    type: 'radio',
    fieldName: 'birthType',
    required: true,
    question: 'Did you have a vaginal or cesarean birth?',
    choices: [
      {
        label: 'Vaginal',
        value: 'vaginal'
      },
      {
        label: 'Cesarean',
        value: 'cesarean'
      }
    ]
  },
  sciatica: {
    type: 'checkbox',
    fieldName: 'sciatica',
    required: true,
    question: 'Do you have any sharp shooting, nerve, tingling pain down your leg?',
    choices: [
      {
        label: 'Pain radiates down the back of your leg',
        value: 'sciatica-top',
        image: sciaticaOne
      },
      {
        label: 'Pain radiates down to into your calf or you have been diagnosed with sciatica',
        value: 'sciatica-full',
        image: sciaticaTwo
      },
      {
        label: 'None of the above',
        value: 'none',
        image: noneTall
      }
    ]
  },
  postMostInterestedIn: {
    type: 'checkbox',
    fieldName: 'mostInterestedIn',
    required: true,
    question: 'What are you most interested in?',
    choices: [
      {
        label: 'Safely returning to exercise',
        value: 'safe-return-exercise'
      },
      {
        label: 'Pelvic floor and core health (pelvic floor dysfunction & Diastasis Recti)',
        value: 'pelvic-floor'
      },
      {
        label: 'Returning to the exercise I love (running, high impact exercise, etc)',
        value: 'return-high-impact'
      },
      {
        label: 'Healing during the 4th trimester (0-12 weeks postpartum)',
        value: '4th-tri-healing'
      },
      {
        label: 'Returning to pre-pregnancy strength and fitness',
        value: 'pre-preg-strength'
      }
    ]
  }
}

