import React, { FC } from 'react';
import { questions } from "../../services/questionnaireFactory/questions";
import {Value, ValueOrArray} from "../../types/questions";
import { setBirthType } from '../../redux/slices/profileSlice';
import {useDispatch} from "react-redux";
import {RadioGroupQuestion} from "../primitives/RadioGroupQuestion";

export const BirthType: FC = () => {

  const dispatch = useDispatch();

  const handleChange = ( selected: ValueOrArray) => {
    dispatch(setBirthType(selected));
  }

  return (
    <RadioGroupQuestion  onChange={handleChange} query={questions.vaginalOrCesareanBirth}/>
  );
};


