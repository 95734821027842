import React, {CSSProperties, FC} from 'react';
import useWindowDimensions from "../../hooks/useWindowDimensions";

interface Props {}

export const QuestionHolder: FC<Props> = ({ children}) => {
  const { isPhone } = useWindowDimensions()

  const styles = {
    container: {
      backgroundColor: 'rgba(255,255,255,0.8)',
      minHeight: '70vh',
      borderRadius: 10,
      padding: isPhone ? 10 : 20
    } as CSSProperties
  }
  return (
    <div style={styles.container}>
      {children}
    </div>
  );
};
