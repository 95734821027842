import React, { FC } from 'react';
import { questions } from "../../services/questionnaireFactory/questions";
import {ValueOrArray} from "../../types/questions";
import { setMostInterestedIn } from '../../redux/slices/profileSlice';
import {useDispatch} from "react-redux";
import {CheckBoxGroupQuestion} from "../primitives/CheckBoxGroupQuestion";

export const PostMostInterested: FC = () => {

  const dispatch = useDispatch();

  const handleChange = ( values: ValueOrArray ) => {
    dispatch(setMostInterestedIn(values));
  }

  return (
    <CheckBoxGroupQuestion query={questions.postMostInterestedIn} onChange={handleChange}/>
  );
};
