import React, {FC, useState} from 'react';
import {CheckBox} from "../../components/primitives/CheckBox";
import {THEME_COLORS} from "../../assets/styles/themecolors";
import {CheckBoxWithLabel} from "../../components/primitives/CheckBoxWithLabel";
import {CheckBoxWithChildren} from "../../components/primitives/CheckBoxWithChildren";
import {CheckBoxGroup} from "../../components/primitives/CheckBoxGroup";
import image from '../../assets/images/questions/pelvic-hypertonic.png'
import {questions} from "../../services/questionnaireFactory/questions";
import {RadioGroup} from "../../components/primitives/RadioGroup";
import backPain from '../../assets/images/questions/back-pain.jpg';

interface Props {
}

const LONG_LABEL = 'Pain radiates down to into your calf or you have been diagnosed with sciatica'

export const TestPage: FC<Props> = (props) => {
  const [chk, setChk] = useState(false);

  return (
    <div style={{backgroundColor: 'white', padding: 30}}>
      <h1>Test</h1>
      <CheckBox checked={chk} onPress={() => setChk(!chk)} color={THEME_COLORS.lightPurple} borderWidth={6}/>
      <CheckBoxWithLabel label={LONG_LABEL} checked={chk} onPress={() => setChk(!chk)} color={THEME_COLORS.pink}
                         labelStyle={{fontSize: 24}} containerStyle={{border: '1px solid green', padding: 20}}
                         dimWhenNotSelected/>
      <CheckBoxWithLabel label={LONG_LABEL} checked={chk} onPress={() => setChk(!chk)} color={THEME_COLORS.pink}
                         labelStyle={{fontSize: 24}} containerStyle={{border: '1px solid green', padding: 20}}
                         dimWhenNotSelected radio/>

      <CheckBoxWithChildren label={LONG_LABEL} checked={chk} onPress={() => setChk(!chk)} color={THEME_COLORS.pink}
                            labelStyle={{fontSize: 24}}
                            outerContainerStyle={{border: '1px solid red'}}
                            containerStyle={{width: 200, alignSelf: 'center'}}>
        <img src={image} style={{width: 200, alignSelf: 'center', marginBottom: 10, padding: 40}}/>
      </CheckBoxWithChildren>
      <CheckBoxGroup entries={questions.pelvicPain.choices} onChange={(vals) => console.log(vals)} clearOthersOnNone />
      <CheckBoxGroup entries={questions.pelvicPain.choices} onChange={(vals) => console.log(vals)} radio />
      <RadioGroup entries={questions.backPain.choices} onChange={(vals => console.log(vals))}>
        <img src={backPain} style={{width: 300}}/>
      </RadioGroup>
    </div>
  );
};
