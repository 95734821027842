import React, {FC} from 'react';
import {MultipleChoiceQuestion, ValueOrArray} from "../../types/questions";
import {CheckBoxGroup} from './CheckBoxGroup';

interface CheckboxGroupQuestionProps {
  query: MultipleChoiceQuestion<any>
  onChange: (state: ValueOrArray) => void;
}

export const CheckBoxGroupQuestion: FC<CheckboxGroupQuestionProps> = ({
                                                                  query,
                                                                  onChange
                                                                }) => {

  const {choices, question, required, image} = query;
  return (
    <div>
      <h2 className="mb-4">{question}</h2>
      <CheckBoxGroup
        entries={choices}
        onChange={onChange}
      />
    </div>
  );
};
