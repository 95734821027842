import React, { FC } from 'react';
import { questions } from "../../services/questionnaireFactory/questions";
import {ValueOrArray} from "../../types/questions";
import { setSciatica } from '../../redux/slices/profileSlice';
import {useDispatch} from "react-redux";
import {CheckBoxGroupQuestion} from "../primitives/CheckBoxGroupQuestion";


export const SciaticaPain: FC = () => {

  const dispatch = useDispatch();

  const handleChange = ( selected: ValueOrArray) => {
    dispatch(setSciatica(selected));
  }

  return (
    <CheckBoxGroupQuestion query={questions.sciatica} onChange={handleChange}/>
  );
};
