import React, {CSSProperties, FC} from 'react';
import {CheckBoxWithLabel, CheckBoxWithLabelProps} from "./CheckBoxWithLabel";

export interface CheckBoxWithHeroProps extends CheckBoxWithLabelProps {
  outerContainerStyle?: CSSProperties
}


export const CheckBoxWithChildren: FC<CheckBoxWithHeroProps> = ({
                                                              outerContainerStyle,
                                                              children,
                                                              ...rest
                                                            }) => {

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column'
    } as CSSProperties
  }

  return (
    <div style={{...styles.container, ...outerContainerStyle}}>
      {children}
      <p/>
      <CheckBoxWithLabel {...rest} />
    </div>
  );
};




