import React, {CSSProperties, FC, useState} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import {Col, Container, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import { setDate } from '../../redux/slices/profileSlice';

const selectDate = ( state: RootState ) => state.profile.dueOrBirthDate;

export const PickBirthDate: FC = (isPreg) => {
  const date = useSelector(selectDate);
  const dispatch = useDispatch();

  const handleDateChange = (ev: Date | [Date, Date] | null) => {
    dispatch(setDate(ev as Date));
  }

  return (
    <div style={styles.container}>
      <h4 style={{alignSelf: 'center'}}>When did you most recently give birth?</h4>
      <Container>
        <Row>
          <Col/>
          <Col sm={6} className="p-5">
            <DatePicker onChange={handleDateChange} selected={date}/>
          </Col>
          <Col/>
        </Row>
      </Container>
    </div>
  );
};
export const PickDueDate: FC = () => {
  const [date, setDate] = useState<Date | null | undefined>(new Date());

  const handleDateChange = (ev: Date | [Date, Date] | null) => {
    setDate(ev as Date);
  }

  return (
    <div style={styles.container}>
      <h4 style={{alignSelf: 'center'}}>When is your baby due?</h4>
      <Container>
        <Row>
          <Col/>
          <Col sm={6}>
            <DatePicker onChange={handleDateChange} selected={date}/>
          </Col>
          <Col/>
        </Row>
      </Container>
    </div>
  );
};


const styles = {
  container: {
    padding: 20,
    borderRadius: 10,
    //border: '2px solid #e0e0e0',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  } as CSSProperties,
  heroImg: {
    height: 400,
    padding: 20,
    alignSelf: 'center'
  },
  radioImg: {
    height: 200,
    //border: '1px solid #f0f0f0',
    padding: 30
  }
}
