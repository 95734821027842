import React, {FC, useState} from 'react';
import {LabelValue} from "../../types/questions";
import {CheckBoxWithChildren} from "./CheckBoxWithChildren";
import {THEME_COLORS} from "../../assets/styles/themecolors";

export type Value = string | number;

interface RadioGroupProps {
  entries: LabelValue<Value>[];
  onChange: (state: Value | Value[]) => void;
}

export const RadioGroup: FC<RadioGroupProps> = ({entries, onChange, children}) => {

  const [selected, setSelected] = useState<Value | undefined>(undefined);

  const toggleValue = (value: Value) => {
    setSelected(value);
    onChange(value);
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center'}}>
      { children }
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        {entries.map((e) => <CheckBoxWithChildren
            onPress={() => toggleValue(e.value)}
            checked={selected === e.value}
            label={e.label}
            color={THEME_COLORS.darkPurple}
            radio={true}
            labelStyle={{fontSize: 24}}
            outerContainerStyle={{width: 200, marginBottom: 30}}
          >
            {e.image ? <img src={e.image} style={{width: 150, alignSelf: 'center'}}/> : null}
          </CheckBoxWithChildren>
        )}
      </div>
    </div>
  );
};
