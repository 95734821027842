import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';

export interface ProfileState {
  dueOrBirthDate: Date;
  pregnancyState?: string;
  pelvicFloorConcerns: string[];
  backPain?: string;
  roundLigamentPain?: string;
  pelvicPain: string[];
  sciatica?: string;
  diastasisRecti?: string;
  mostInterestedIn: string[];
  // for trying clients
  previousInjuries?: string;
  medicalConditions?: string;
  anythingElse?: string;
  birthType?: string;
}

const initialState: ProfileState = {
  dueOrBirthDate: new Date(),
  // prenatal, postnatal, trying
  pregnancyState: undefined,
  pelvicFloorConcerns: [],
  backPain: undefined,
  roundLigamentPain: undefined,
  pelvicPain: [],
  sciatica: undefined,
  diastasisRecti: undefined,
  mostInterestedIn: [],
  // for trying clients
  previousInjuries: '',
  medicalConditions: '',
  anythingElse: '',
  birthType: undefined
}

const setter = (fieldName: string) => (state: Draft<any>, action: PayloadAction<any>) => {
  state[fieldName] = action.payload;
}

export const profileSlice = createSlice({
  name: 'prenatal',
  initialState,
  reducers: {
    setDate: setter('dueOrBirthDate'),
    setPregnancyState: setter('pregnancyState'),
    setPelvicFloorConcerns: setter('pelvicFloorConcerns'),
    setBackPain: setter('backPain'),
    setRoundLigamentPain: setter('roundLigamentPain'),
    setPelvicPain: setter('pelvicPain'),
    setSciatica: setter('sciatica'),
    setDiastisisRecti: setter('diastasisRecti'),
    setMostInterestedIn: setter('mostInterestedIn'),
    setPreviousInjuries: setter('previousInjuries'),
    setMedicalConditions: setter('medicalConditions'),
    setAnythingElse: setter('anythingElse'),
    setBirthType: setter('birthType')
  }
})

export const { setBackPain, setDiastisisRecti, setMostInterestedIn,
  setPelvicFloorConcerns, setPelvicPain, setRoundLigamentPain, setSciatica, setPregnancyState,
  setPreviousInjuries, setMedicalConditions, setAnythingElse, setBirthType, setDate } = profileSlice.actions;

export default profileSlice.reducer
